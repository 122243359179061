<template>
  <div class="contact-root-container">
    <div class="contact-header-container">
      <div class="contact-header-left-container">
        <span class="contact-header-title">全部个人用户</span>
      </div>
      <img @click="close" :src="icons.upArrow" class="project-arrow" />
    </div>
    <div class="contact-main-container">
      <div class="page-title">全部个人用户</div>
      <Input
        prefix="ios-search"
        v-model="search_content"
        @keyup.enter.native="getList"
        placeholder="搜索"
        style="width: 250px; margin-top: 20px"
      />
      <div class="contact-list-root-container">
        <div class="contact-left-container">
          <div style="overflow-y: scroll">
            <div
              v-for="(memberItem, index) in memberMap"
              :key="index"
              style="margin-bottom: 30px"
            >
              <div
                style="font-size: 23px; font-weight: bold; color: #3c3c3c"
                :id="index"
              >
                {{ index }}
              </div>
              <div class="contact-list-container">
                <contact-item
                  style="margin: 10px"
                  v-for="item in memberItem"
                  :key="item.client_user_id"
                  :data-source="item"
                  @click.native="showDetail(item.client_user_id)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="contact-right-container">
          <a
            v-for="(item, index) in keyList"
            class="contact-head-item"
            :href="'#' + item"
            :key="index"
            >{{ item }}</a
          >
        </div>
      </div>
    </div>
    <contact-detail-modal ref="contactDetailModal"></contact-detail-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import moment from "moment";
import * as pyHelper from "../../utils/pyHelper";
import ContactItem from "./Item/ContactItem";
import ContactDetailModal from "./Modal/ContactDetailModal";
export default {
  components: {
    "contact-item": ContactItem,
    "contact-detail-modal": ContactDetailModal,
  },
  data() {
    return {
      icons: icon,
      keyList: [],
      memberMap: {},
      search_content: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions({
      contactGetListAction: "contactGetList",
    }),
    getList() {
      this.memberMap = {};
      this.keyList = [];
      this.contactGetListAction({
        search_content: this.search_content,
      })
        .then((res) => {
          this.userList = res;

          let tempMap = {};
          for (let item of this.userList) {
            let firstPY = pyHelper.makePy(item.client_user_name[0])[0];
            if (this.keyList.indexOf(firstPY) == -1) {
              this.keyList.push(firstPY);
              tempMap[firstPY] = [item];
            } else {
              tempMap[firstPY].push(item);
            }
          }
          this.keyList.sort();
          for (let item of this.keyList) {
            this.memberMap[item] = tempMap[item];
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    showDetail(id) {
      this.$refs.contactDetailModal.open(id);
    },
    close() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
.contact-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f7f7f7;
  overflow: hidden;
}
.contact-header-container {
  background-color: #313f4a;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.contact-header-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.contact-header-title {
  margin-left: 40px;
  color: #fff;
  font-size: 15px;
}
.contact-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  margin: 50px 0px 50px 200px;
}
.contact-list-root-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  overflow: hidden;
}
.contact-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.contact-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  background: #2a2828;
  color: #ffffff;
  border-radius: 15px;
  padding: 0px 10px;
  margin-right: 40px;
  margin-bottom: 20px;
  width: 30px;
}
.contact-head-item {
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease;
}
.contact-head-item:hover {
  transform: scale(1.5);
}
.contact-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>